import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import app from '../assets/1.png'
import credit from '../assets/3.png'
import { Link as ScrollLink } from 'react-scroll';
import pycaret from '../assets/pycaret.png'

const P1 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const goBack = () => {
    const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

    const scrollToTargetSection = () => {
      const targetElement = document.getElementById(targetSectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
  };

  const menuItemVariants = {
    hidden: { opacity: 0, x: -200 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  return (
    <div name="top" id="p1" className="w-full bg-[#ffdfad] text-[#101436] mx-auto text-center"  style={{ fontFamily: "futura"}}>
      {/*Menu*/}
      <ul className="h-screen flex flex-col justify-center items-center md:text-6xl font-bold text-black sm:text-5xl" style={{ fontFamily: "futura" }}>
      <motion.li
        variants={menuItemVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="hover:text-[#ff7700] bg-[#FFF2DB] rounded-lg p-10 shadow-lg mb-10"
      >
        <ScrollLink to="project1" smooth={true} duration={1000}>
          Regression project
        </ScrollLink>
      </motion.li>
      <motion.li
        variants={menuItemVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="hover:text-[#ff7700] bg-[#FFF2DB] rounded-lg p-10 shadow-lg mb-10"
      >
        <ScrollLink to="project2" smooth={true} duration={1000}>
          Classification project
        </ScrollLink>
      </motion.li>
      <motion.li
        variants={menuItemVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="hover:text-[#ff7700] bg-[#FFF2DB] rounded-lg p-10 shadow-lg mb-10"
      >
        <ScrollLink to="project3" smooth={true} duration={1000}>
          AutoML project
        </ScrollLink>
      </motion.li>
      {/* Back to Projects Link */}
      <motion.div
        variants={menuItemVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="bg-[#42e480] rounded-lg p-10 shadow-lg"
      >
        <Link to="/" onClick={goBack}>
          <p className="back-arrow text-[#000000] font-bold md:text-6xl hover:text-[#ff7700] sm:text-4xl">&larr; Back to Projects menu</p>
        </Link>
      </motion.div>
    </ul>

      <div className="flex flex-col justify-center items-center w-full">
      <p name="project1" className="pt-1 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-4">Regression Project </p>
        {/* Project 1: Regression */}
        <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-5 bg-[#FFF2DB] rounded-lg p-4 shadow-lg mb-5 text-[#000000]" style={{ fontFamily: "futura"}}>
          <div>
            <h2 className="lg:text-3xl font-bold mb-4 underline">Predicting Real Estate Market Price</h2>
            <p className='lg:text-2xl' style={{ textAlign: "left"}}>
              This project aims to provide accurate and insightful predictions of property prices based on various features. 
              By leveraging machine learning algorithms, the system analyzes historical property data, taking into account factors such as district, square footage, number of bedrooms, and other relevant attributes.
            </p>

            {/* Additional Information */}
            <div className="mt-6">
              <h2 className="underline lg:text-3xl font-bold mb-2">About the Dataset</h2>
              <p className="lg:text-2xl" style={{ textAlign: "left"}}>
                The dataset used is about Milwaukee's (US state) Real Estate sales data from the year 2002-2022. It is obtained from Milwaukee's Open Data Portal. You can download the dataset from <a href="https://data.milwaukee.gov/dataset/property-sales-data" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">here</a>.
              </p>
            </div>
             {/* Image Gallery for Project 1 */}
          <div className='' style={{ alignItems: 'center' }}>
          <h2 className="lg:text-3xl font-bold mb-4 underline pt-6">
            <a
              href="https://real-estate-price-prediction-app.streamlit.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-[#ffffff]"
            >
             Test out my Streamlit app 
            </a>
          </h2>
          <img className='pl-20 pr-5' src={app} alt="icon" title='ReactJS' style={{ width: '700px', height: '300px' }} />
            </div>
          </div>

          <div>
            <h2 className="underline lg:text-3xl font-bold mb-1">Results:</h2>
            <table className="min-w-full border mt-5 border-black">
              <thead>
                <tr>
                  <th className="border p-2 lg:text-2xl border-black">Model</th>
                  <th className="border p-2 lg:text-2xl border-black">Metric</th>
                  <th className="border p-2 lg:text-2xl border-black">Value</th>
                </tr>
              </thead>
              <tbody>
                {/* Linear Regression */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="3">Linear Regression</td>
                  <td className="border p-2 border-black">MSE</td>
                  <td className="border p-2 border-black">2,960,230,826</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">MAE</td>
                  <td className="border p-2 border-black">34,375</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">R2</td>
                  <td className="border p-2 font-bold text-[#ff0000] border-black">0.679</td>
                </tr>

                {/* Random Forest Regressor */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="3">Random Forest Regressor</td>
                  <td className="border p-2 border-black">MSE</td>
                  <td className="border p-2 border-black">2,047,237,885</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">MAE</td>
                  <td className="border p-2 border-black">28,953</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">R2</td>
                  <td className="border p-2 font-bold text-[#ff0000] border-black ">0.778</td>
                </tr>

                {/* XGBoost Regressor */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="3">XGBoost Regressor</td>
                  <td className="border p-2 border-black">MSE</td>
                  <td className="border p-2 border-black">1,939,965,795</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">MAE</td>
                  <td className="border p-2 border-black">28,216</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">R2</td>
                  <td className="border p-2 font-bold text-[#ff0000] border-black ">0.790</td>
                </tr>

                {/* Fine-tuned Model: XGBoost Regressor */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="3">Fine-tuned XGBoost Regressor</td>
                  <td className="border p-2 border-black">MSE</td>
                  <td className="border p-2 border-black">1,785,590,381</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">MAE</td>
                  <td className="border p-2 border-black">27,957</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">R2</td>
                  <td className="border p-2 font-bold text-[#ff0000] border-black ">0.806 </td>
                </tr>
              </tbody>
            </table>

            <ul className='lg:text-1xl'>
            </ul>
             {/* GitHub Link for Project 1 */}
          <div className="max-w-[1000px] w-full mt-2 mb-4">
            <h2 className="lg:text-3xl font-bold mb-2 underline">Check out my Github Repository for more info: </h2>
            <a 
              href="https://github.com/ongaunjie1/real-estate-price-prediction"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
            >
              View on GitHub
            </a>
          </div>
          <li className=' bg-[#f3943b] rounded-lg p-1 list-none mb-4'>
                <ScrollLink to='top' smooth={true} duration={1000} className='text-[#000000] font-bold lg:text-3xl hover:text-[#ffffff]'>
                Select another project
              </ScrollLink>
                </li>
           {/* Back to Projects Link */}
       <div className=' bg-[#42e480] rounded-lg p-1'>
            <Link to="/" onClick={goBack}>
              <p className="back-arrow text-[#000000] font-bold lg:text-3xl hover:text-[#ffffff]">&larr; Back to Projects</p>
            </Link>
          </div>
          </div>
        </div>


        <p name="project2" className="lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5 mt-20 pt-5">Multi-class Classification Project </p>
        {/* Project 2: Classification */}
        <div className="mb-5 max-w-[1700px] w-full grid sm:grid-cols-2 gap-5 bg-[#FFF2DB] rounded-lg p-4 shadow-lg text-[#000000]" style={{ fontFamily: "futura" }}>
          <div>
            <h2 className="lg:text-3xl font-bold mb-4 underline">Credit score prediction </h2>
            <p className='lg:text-2xl' style={{ textAlign: "left"}}>
            This project aims to build a predictive model capable of categorizing individuals into three distinct credit score classes: good, average, and bad.
            The project employs machine learning techniques to assess various financial and non-financial features, providing a nuanced evaluation of creditworthiness.
            </p>

            {/* Additional Information */}
            <div className="mt-6">
              <h2 className="underline lg:text-3xl font-bold mb-2">About the Dataset</h2>
              <p className="lg:text-2xl" style={{ textAlign: "left"}}>
              The dataset was taken from <a href="https://www.kaggle.com/datasets/parisrohan/credit-score-classification/data" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">here</a>. 
              It contains essential bank details and credit-related information. Notably, the dataset is not pristine and exhibits inconsistencies. Nonetheless, navigating and addressing
              these challenges provides a valuable learning experience in the process of cleaning and refinining raw datasets.
              </p>
            </div>
             {/* Image Gallery for Project 1 */}
          <div className='' style={{ alignItems: 'center' }}>
          <h2 className="lg:text-3xl font-bold mb-4 underline pt-6">
            <a
              href="https://credit-score-prediction.streamlit.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-[#ffffff]"
            >
             Test out my Streamlit app 
            </a>
          </h2>
          <img className='pl-20 pr-5' src={credit} alt="icon" title='ReactJS' style={{ width: '700px', height: '300px' }} />
            </div>
          </div>

          <div>
            <h2 className="underline text-3xl font-bold mb-1">Results:</h2>
            <table className="min-w-full border mt-5 border-black">
              <thead>
                <tr>
                  <th className="border p-2 lg:text-2xl border-black">Model</th>
                  <th className="border p-2 lg:text-2xl border-black">Metric</th>
                  <th className="border p-2 lg:text-2xl border-black">Value</th>
                </tr>
              </thead>
              <tbody className='text-sm'>
                {/* XGBoost Regressor */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="4">XGBoost Classifier</td>
                  <td className="border p-2 border-black">Accuracy</td>
                  <td className="border p-2 font-bold border-black  text-[#ff0000]">0.776</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Precision</td>
                  <td className="border p-2 border-black">0.775</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Recall</td>
                  <td className="border p-2  border-black ">0.776</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">F1 Score</td>
                  <td className="border p-2  border-black ">0.774</td>
                </tr>

                {/* Fine-tuned Model: XGBoost Regressor */}
                <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="4">XGBoost Classifier (randomized search)</td>
                  <td className="border p-2 border-black">Accuracy</td>
                  <td className="border p-2 font-bold border-black text-[#ff0000]">0.793</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Precision</td>
                  <td className="border p-2 border-black">0.792</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Recall</td>
                  <td className="border p-2  border-black ">0.793 </td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">F1 Score</td>
                  <td className="border p-2  border-black ">0.792
                </td>
                </tr>
              </tbody>
              {/* Random Forest Classifier */}
              <tr>
                  <td className="border p-2 lg:text-2xl font-bold border-black" rowSpan="4">Random Forest Classifier</td>
                  <td className="border p-2 border-black">Accuracy</td>
                  <td className="border p-2 font-bold border-black text-[#ff0000]">0.795</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Precision	</td>
                  <td className="border p-2 border-black">0.795</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">Recall</td>
                  <td className="border p-2 border-black ">	0.795</td>
                </tr>
                <tr>
                  <td className="border p-2 border-black">F1 Score</td>
                  <td className="border p-2  border-black ">0.794</td>
                </tr>
            </table>
            <ul className='lg:text-1xl'>
            </ul>
             {/* GitHub Link for Project 1 */}
          <div className="max-w-[1000px] w-full mt-4">
            <h2 className="lg:text-3xl font-bold mb-2 underline ">Check out my Github Repository for more info: </h2>
            <a 
              href="https://github.com/ongaunjie1/credit-score-prediction"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
            >
              View on GitHub
            </a>
            <li className=' bg-[#f3943b] rounded-lg p-1 list-none mb-4 mt-4'>
                <ScrollLink to='top' smooth={true} duration={1000} className='text-[#000000] font-bold lg:text-3xl hover:text-[#ffffff]'>
                Select another project
              </ScrollLink>
                </li>
          </div>
           {/* Back to Projects Link */}
          <div className=' bg-[#42e480] rounded-lg p-1'>
            <Link to="/" onClick={goBack}>
              <p className="back-arrow text-[#000000] font-bold lg:text-3xl hover:text-[#ffffff]">&larr; Back to Projects</p>
            </Link>
          </div>
          </div>
         </div>
        
        
        <p name="project3" className=" lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mt-20 pt-4 mb-4">AutoML using PyCaret and Pandas-profiling </p>
        {/* Project 3: automl */}
        <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-5 bg-[#FFF2DB] rounded-lg p-4 shadow-lg text-[#000000] mb-5 mt-3 " style={{ fontFamily: "futura" }}>
          <div>
            <h2 className="lg:text-3xl font-bold mb-2 underline">What is PyCaret</h2>
            <p className='lg:text-2xl' style={{ textAlign: "left"}}>
            PyCaret is an open-source, low-code maching learning library in Python that automates machine learning workflows. It is an end-to-end machine learning and Model management
            tool that exponentially speeds up experiment cycle and makes you more productive.
            </p>

            {/* Additional Information */}
            <div className=''>
              <h2 className="underline lg:text-3xl font-bold mb-2">What is Pandas-profiling</h2>
              <p className="lg:text-2xl" style={{ textAlign: "left"}}>
              Pandas-profiling is a Python library that generates exploratory data analysis (EDA) reports from Pandas DataFrames. 
              It simplifies the process of understanding and visualizing the characteristics of a dataset.
              </p>
            </div>
            <h2 className="underline lg:text-3xl font-bold mb-2 mt-4">Goal of project</h2>
              <p className="lg:text-2xl" style={{ textAlign: "left"}}>
              The goal of this project is to create a straightforward AutoML app capable of rapidly assessing the performance of various ML algorithms on a dataset. This enables a streamlined model selection process. 
              Additionally, I utilized Docker to containerize the app, facilitating efficient deployment and reproducibility.
              </p>
              <br />
              <p className='underline font-bold lg:text-3xl mb-2'>Docker Image</p>
              <ul className='lg:text-2xl' style={{ textAlign: 'left' }}>
                <li><span className='font-bold'>Pull command:</span> docker pull ongaunjie1/automl-app:latest</li>
                <li> <span className='font-bold'>Run command: </span>docker run -d -p 8501:8501 ongaunjie1/automl-app:latest</li>
              </ul>
          
              <div>
                <p className='font-bold lg:text-3xl mt-4 underline mb-2'>Future improvements for the app:</p>
                <p className='lg:text-2xl' style={{textAlign:'left'}}>
                  - Add other features of PyCaret to the app, For example, fine-tuning, model evaluation, and etc. Refer 
                  <a 
                    href="https://pycaret.gitbook.io/docs/get-started/preprocessing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 lg:text-2xl hover:text-[#ffffff] pl-2 pr-2 underline"
                  >
                  here
                  </a> 
                  for the documentations of PyCaret.
                </p>
              </div>
          </div>

          <div>
             {/* Image Gallery for Project 1 */}
          <div className='' style={{ alignItems: 'center' }}>
          <h2 className="lg:text-3xl font-bold mb-4 underline">
            <a
              href="https://automlapp-pycaret.streamlit.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline hover:text-[#ffffff]"
            >
             Test out my Streamlit app 
            </a>
          </h2>
          <img className='pl-20 pr-5' src={pycaret} alt="icon" title='ReactJS' style={{ width: '700px', height: '300px' }} />
          <br />
          <p className='lg:text-2xl' style={{textAlign:'left'}}>Note: The app created only uses PyCaret's default settings for data cleaning and data preprocessing. Hence, it is recommended that you clean/pre-process your data before performing modeling with the app. 
                However, you can utilize pandas-profiling from the app to get an overview of the statistical insights from your dataset.
              </p>
            <br />
            </div>
            <ul className='lg:text-1xl'>
            </ul>
             {/* GitHub Link for Project 1 */}
          <div className="max-w-[1000px] w-full mt-2 mb-8">
            <h2 className="lg:text-3xl font-bold mb-2 underline">Check out my Github Repository for more info: </h2>
            <a 
              href="https://github.com/ongaunjie1/pycaret_automl_streamlit"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
            >
              View on GitHub
            </a>
          </div>
          <li className=' bg-[#f3943b] rounded-lg p-1 list-none mb-4'>
                <ScrollLink to='top' smooth={true} duration={1000} className='text-[#000000] font-bold lg:text-3xl hover:text-[#ffffff]'>
                Select another project
              </ScrollLink>
                </li>
           {/* Back to Projects Link */}
          <div>
            <Link to="/" onClick={goBack}>
              <p className="hover:text-[#ffffff] back-arrow text-[#000000] font-bold lg:text-3xl bg-[#42e480] rounded-lg p-1">&larr; Back to Projects menu</p>
            </Link>
          </div>
          </div>
         </div>
        </div>
      </div>
    );
  };

  export default P1;

import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Project from './components/Projects';
import Contact from './components/Contact';
import P1 from './components/Project1';
import P2 from './components/Project2';
import P3 from './components/Project3';
import P4 from './components/Project4';
import P5 from './components/Project5';
import P6 from './components/Project6';
import Chatbot from './components/Chatbot';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <div>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Navbar />
                <Home />
                <About />
                <Skills />
                <Project />
                <Contact/>
              </div>
            }/>
            <Route path="/project1" element={<P1/>} />
            <Route path="/project2" element={<P2/>} />
            <Route path="/project3" element={<P3/>} />
            <Route path="/project4" element={<P4/>} />
            <Route path="/project5" element={<P5/>} />
            <Route path="/project6" element={<P6/>} />
          
        </Routes>
      </div>
      <Chatbot/>
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
import app from '../assets/1.png'
import table from '../assets/31.png'
import dec from '../assets/dec.png'
import car from '..//assets/car.png'
import read from '../assets/read.png'
  
const P2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
const goBack = () => {
  const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

  const scrollToTargetSection = () => {
    const targetElement = document.getElementById(targetSectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
};

  return (
    <motion.div 
    ref={ref}
    initial={{ opacity: 1, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0}}
    transition={{ duration: 0.8 }}
    id="p2" name="project2" className="w-full bg-[#ffdfad] text-[#101436] md:pb-10 mx-auto text-center"style={{ fontFamily: "futura" }}>
    <div className="flex flex-col justify-center items-center w-full">
    <p className="pt-5 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5">Object Detection using YOLOv8</p>
      {/* Project 1: Regression */}
      <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-5 bg-[#FFF2DB] rounded-lg p-4 shadow-lg mb-20 text-[#000000]" style={{ fontFamily: "futura" }}>
        <div>
          <h2 className="lg:text-3xl font-bold mb-4 underline">Project Goal</h2>
          <p className='lg:text-2xl' style={{textAlign:'left'}}>
          This project aims to utilize <span className='font-bold text-orange-700'>YOLOv8</span> for creating custom-trained models in diverse applications, including <span className='font-bold text-orange-700'>license plate detection</span> , <span className='font-bold text-orange-700'>potholes detection</span>, 
          and Personal Protective Equipment <span className='font-bold text-orange-700'>(PPE) detection</span>. Additionally, the trained-models will be utilized <br />into creating a fully functional object-detection app using <span className='font-bold text-orange-700'>Streamlit</span>.
          </p>

          {/* Additional Information */}
          <div className="mt-6">
            <h2 className="underline lg:text-3xl font-bold mb-2">General steps on custom-training a YOLOv8 model</h2>
            <ul className='lg:text-2xl'>
              <div className='pl-7 pt-2' style={{ textAlign: "left" }}> <p className='font-bold'>1. Annotate Images:</p>&bull; Annotate dataset with bounding boxes around objects of interest. Tools like RoboFlow or CVAT can be used for this. <br />
              <p className='font-bold pt-4'>2. Split Data:</p>&bull; Divide dataset into training and validation sets. The training set is used to train the model, while the validation set is used to evaluate its performance during training.<br /> 
              <p className='font-bold pt-4'>3. Pre-process the images:</p>&bull; Standard image pre-processing steps are resizing images to the same size to ensure consistency. Default value used for yolov8 training is 640x640.
              <p className='font-bold pt-4'>4. Augment Images (if needed):</p>&bull; Data augmentation techniques can be applied to artificially increase the diversity <br /> of your dataset. This helps the model generalize better.<br /> 
              <p className='font-bold pt-4'>5. Select YOLOv8 model variant:</p>&bull; A model with more parameters will generally perform better in terms of accuracy <br />but comes at the cost of slower inference speed.<br /> 
              <img src={table} alt="table" />
              <p className='font-bold pt-4'>6. Train the model: (<span className='text-orange-700'>NOTE: model trained using yolov8's default hyperparameters</span>)</p>&bull; Fine-tune the YOLOv8 model on the annotated dataset using the training set. Monitor metrics such as loss and mAP during training.<br /> 
              <p className='font-bold pt-4'>7. Validate the Model:</p>&bull; Evaluate the trained model on the validation set to ensure it generalizes well <br />to new, unseen data.<br /> 
              <p className='font-bold pt-4'>8. Inference on Test Set:</p>&bull; After training, perform inference on a separate test set to assess the model's performance on completely unseen data.<br /> 
              </div>
            </ul>
          </div>
          <h2 className="underline lg:text-3xl font-bold mb-1 pt-8">Custom-trained model's result:</h2>
      <table className="min-w-full border mt-5 border-black">
        <thead>
          <tr>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '300px' }}>Model</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '150px' }}>mAP50</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '150px' }}>mAP50-95</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {/* Potholes detection */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Potholes detection (yolov8m)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.721</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.407</td>
          </tr>

          {/* Car License plate detection */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Car License plate detection (yolov8m)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.995</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.828</td>
          </tr>

          {/* PPE Detection */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">PPE Detection (yolov8m)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.991</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.738</td>
          </tr>
        </tbody>
      </table>
        </div>
        <div>
        <div>
    </div>
            {/* link for Project*/}
        <div className='' style={{ alignItems: 'center' }}>
        <h2 className="lg:text-3xl font-bold mb-4 underline">
          <a
            href="https://yolov8-app.streamlit.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-[#ffffff]"
          >
           Test out my Streamlit app 
          </a>
        </h2>
        <img className='pl-10 pr-3' src={dec} alt="icon" title='ReactJS' style={{ width: '800px', height: '400px' }} />
        <p className='pl-10 underline pt-3 pb-3 lg:text-2xl font-bold' style={{ textAlign: "left" }}>Example 1: Detecting car license plate</p>
        <img className='pl-10 pr-3' src={car} alt="icon" title='ReactJS' style={{ width: '800px', height: '400px' }} />
        <p className='pl-10 underline pt-3 pb-3 lg:text-2xl font-bold' style={{ textAlign: "left" }}>Example 2: Reading license plate using EasyOCR</p>
        <img className='pl-10 pr-3' src={read} alt="icon" title='ReactJS' style={{ width: '800px', height: '400px' }} />
        <li className='lg:text-2xl pl-5 pt-5 font-bold' style={{textAlign:'left'}}>Learn more about what is EasyOCR and how it works at my github repository.</li>
          </div>
          <ul className='lg:text-1xl'>
          </ul>
           {/* GitHub Link for Project 1 */}
        <div className="max-w-[1000px] w-full mt-4 mb-10 pt-5">
          <h2 className="lg:text-3xl font-bold mb-2 underline">Check out my Github Repository for more info: </h2>
          <a 
            href="https://github.com/ongaunjie1/YOLOv8-streamlit-app"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
            View on GitHub
          </a>
        </div>
         {/* Back to Projects Link */}
        <div className=' bg-[#42e480] rounded-lg p-10'>
              <Link to="/" onClick={goBack}>
                <p className="back-arrow text-[#000000] font-bold lg:text-4xl">&larr; Back to Projects</p>
              </Link>
            </div>
        </div>
      </div>
    </div>
</motion.div>
  );
};

export default P2;


import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
import bot from '../assets/bot.png'
import c2 from '../assets/c2.png'
import json from '../assets/json.png'
  
const P6 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
const goBack = () => {
  const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

  const scrollToTargetSection = () => {
    const targetElement = document.getElementById(targetSectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
};

  return (
    <motion.div
    ref={ref}
    initial={{ opacity: 1, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0}}
    transition={{ duration: 0.8 }}
    id="p6" name="project6" className="w-full bg-[#ffdfad] text-[#101436] md:pb-10 mx-auto text-center"style={{ fontFamily: "futura" }}>
    <div className="flex flex-col justify-center items-center w-full">
    <p className="pt-5 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5">Chatbot Development</p>
      {/* Project*/}
      <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-20 bg-[#FFF2DB] rounded-lg p-4 shadow-lg  text-[#000000]" style={{ fontFamily: "futura" }}>
        <div>
          <h2 className="lg:text-3xl font-bold mb-2 underline">Creating a Chatbot using Deep Learning and NLP</h2>
          <p className='lg:text-2xl pl-4' style={{ textAlign: "left"}}>
          This project focuses on creating a smart chatbot using <span className='font-bold text-orange-700'>deep learning</span> and <span className='text-orange-700 font-bold'>Natural Language Processing (NLP)</span>. The primary goal is to build an intent-based conversational virtual assistant capable of understanding and responding to user inputs in a human-like manner.</p>
       
          {/* Additional Information */}
          <div className="mt-1">
            <h2 className="underline lg:text-3xl font-bold mb-1">Project components</h2>
            <li className='lg:text-lg font-bold mb-2 mt-2' style={{textAlign:'left'}}>This project is made up of four essential components, and they all work together to make a full and effective chatbot.</li>
            <ul className='lg:text-2xl'>
              <div className='pl-3 mb-6' style={{ textAlign: "left" }}> 
              <p className='pb-2 font-bold'>1. Development of an intent-based chatbot:</p>  
              <li> &bull;<span className='font-bold text-orange-700'> A feed-forward neural network</span> was used to train the intent-classification model</li>  
              <li> &bull; <span className='font-bold text-orange-700'> Utilizing NLTK techniques</span> such as <span className='font-bold text-orange-700'>tokenization</span>, <span className='font-bold text-orange-700'>stemming</span> and <span className='font-bold text-orange-700'>bag of words</span> to pre-process the training data and as well as user-inputs.</li>
              {/* Table */}
              <table className='border border-black text-lg p-1 mt-4'>
                <thead className='border border-black p-1'>
                  <tr>
                    <th className='p-1 border-black border' style={{ width: '150px', textAlign:'center' }}>Technique</th>
                    <th className='p-1 border-black border' style={{ width: '300px', textAlign:'center' }}>Description</th>
                    <th className='p-1 border-black border' style={{ width: '300px' , textAlign:'center' }}>Usage</th>
                  </tr>
                </thead>
                <tbody className='border border-black p-1' style={{textAlign:'center' }}>
                  <tr className='border border-black p-1'>
                    <td className='border border-black p-1'>Tokenization</td>
                    <td className='border border-black p-1'>Process of breaking text into words or phrases (tokens).</td>
                    <td className='border border-black p-1'>allowing the chatbot to understand and process individual components of user input.</td>
                  </tr>
                  <tr className='border border-black'>
                    <td className='border border-black p-1'>Stemming</td>
                    <td className='border border-black p-1'>Reducing words to their base or root form.</td>
                    <td className='border border-black p-1'>Improves text analysis by grouping similar words.</td>
                  </tr>
                  <tr className='border border-black p-1'>
                    <td className='border border-black p-1'>Bag of Words</td>
                    <td className='border border-black p-1'>Represents text as an unordered set of words.</td>
                    <td className='border border-black p-1'>transforming text into numerical representations that serve as feature vectors for training models to recognize user intents.</td>
                  </tr>
                </tbody>
              </table>

              <div className='mt-4'>
                <li className='mb-2'>&bull; Before training the FNN model, it is required to prepare the training data in a json format.</li>
                <li className='mb-2'>&bull; The <span className='font-bold text-orange-700'>intents.json</span> is a file used to train intent-based chatbots. It defines user intents with names and associated training examples, teaching the chatbot how to recognize and respond to different user inputs. </li>
                <li className='mb-2'>&bull; This file is crucial for natural language understanding, contributing to the chatbot's accuracy and effectiveness in understanding user intent.</li>
                <p className='lg:text-1xl font-bold pl-5 underline'>Below is a snippet example of the training data:</p>
                <img src={json} alt="json" className='pl-5 mt-4' />
              </div>
              
              </div> 
              <div className='pl-3  mr-0 ' style={{ textAlign: "left" }}> 
              <p className='pb-2 font-bold lg:text-2xl' style={{textAlign:'left'}}>2. Integrating the Chatbot with APIs:</p>    
              <li>&bull; The chatbot was integrated with 2 external APIs 
              (<a 
            href="https://developer.themoviedb.org/reference/intro/getting-started"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-2xl hover:text-[#ffffff]"
            >
            themoviedb
            </a> ,
            <a 
            href="https://openweathermap.org/api"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-2xl pl-2 hover:text-[#ffffff]"
            >
            openweather
            </a>)</li>
            <li>&bull; <span className='font-bold text-orange-700'>themoviedb API</span>  allows the chatbot to extract movie details.</li>
            <li>&bull; <span className='font-bold text-orange-700'>openweather API</span> allows the chatbot to extract weather details.</li>
            <li>&bull; Additionally, I also integrated the chatbot with the
            <a 
            href="https://pypi.org/project/wikipedia/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-2xl pr-2 pl-2 hover:text-[#ffffff]"
            >
            wikipedia
            </a>
            library to parse data wikipedia.</li>       
            </div>

            </ul>
          </div>
  </div>

<div>
  <div>
      <div className='pl-3 mb-6 mr-0 lg:text-2xl' style={{ textAlign: "left" }}> 
             <p className='pb-2 font-bold'>3. Establishment of an API endpoint:</p>   
             <p>&bull; Creating an <span className='font-bold text-orange-700'>API endpoint</span> using <span className='font-bold text-orange-700'>flask</span> for front-end applications.</p> 
             <p>&bull; By creating an endpoint, the chatbot now can be integrated with a front-end application</p> 
              <img src={c2} alt="c2" className='mt-4' />
            </div> 

        <div className='pl-3 mb-6 mr-0 mt-4 lg:text-2xl' style={{ textAlign: "left" }}> 
          <p className='font-bold '>4. Designing a user interface for the chatbot using JavaScript, HTML, CSS via the ReactJS framework, ensuring a seamless and interactive user experience:</p>    
          <li className='mt-2'>For this project, I have also designed a <span className='font-bold text-orange-700'>Chatbot UI</span> using front-end tools like <span className='text-orange-700 font-bold'>HTML</span>, <span className='text-orange-700 font-bold'>CSS</span> and <span className='text-orange-700 font-bold'>javascript</span></li> 
          <li className='mt-2'>You can find the source code for the UI design within my github repository if interested. NOTE: I'm using reactJS framework</li>      
          <img src={bot} alt="bot" style={{ width: '400px' , height:'500px' }} className='mt-3' />
        </div>

        </div>
        <p className='lg:text-2xl mt-2' style={{textAlign:'left'}}>&bull; The chatbot created for this project is deployed to a cloud and it is integrated with my personal portfolio, feel free to interact with my chatbot. </p>
           {/* GitHub Link for Project 1 */}
        <div className="max-w-[1000px] w-full  pt-3 mb-2">
          <h2 className="lg:text-3xl font-bold mb-2 underline" style={{textAlign:'left'}}>Check out my Github Repository if you want to learn how to train your own custom-chatbot: </h2>
          <a 
            href="https://github.com/ongaunjie1/AI-Chatbot-DL-NLP"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
           View on Github
          </a>
        </div>
        <div className="max-w-[1000px] w-full mb-5 ">
        </div>
         {/* Back to Projects Link */}
        <div className=' bg-[#42e480] rounded-lg p-4'>
              <Link to="/" onClick={goBack}>
                <p className="back-arrow text-[#000000] font-bold lg:text-4xl hover:text-[#ffffff]">&larr; Back to Projects</p>
              </Link>
            </div>
        </div>
      </div>
    </div>
</motion.div>
  );
};

export default P6;


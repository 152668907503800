import React from 'react';
import { motion } from 'framer-motion';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link as ScrollLink } from 'react-scroll';





const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f] overflow-y-auto pb-20' style={{fontFamily: "ivy" }}>
      {/* Container */}
      <div
        id='home'
        className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full text-white "
      >
        <motion.p
          className='text-3xl pt-40'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Hi, my name is
        </motion.p>
        <motion.h1
          className='sm:text-7xl text-4xl font-bold text-[#ffed9e]'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          Ong Aun Jie
        </motion.h1>
        <motion.h2
          className='sm:text-6xl text-2xl mb-3 font-bold text-[#df9550]'
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          I'm a self-taught Data Scientist.
        </motion.h2>
        <motion.p
          className='mb-3 sm:text-2xl' style={{fontFamily: "futura" ,fontSize: "2rem" }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration:0.6, delay: 0.6 }}
        >
        Explore my portfolio to learn more about me and dive into the projects section for a closer look at my work. Let's connect through data !
        </motion.p>
        <div className='text-1xl sm:text-2xl text-yellow-300'>
        <div className='flex items-center'>
         <ScrollLink to='pg4' smooth={true} duration={1000}>
         <motion.button
        className='group border-2 rounded flex items-center px-2 py-3 pt-3 my-2 hover:bg-black border-yellow-300'
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.8 }}
         >
        View projects
        <span className='group-hover:rotate-90 duration-300'>
          <HiArrowNarrowRight className='ml-3' />
        </span>
          </motion.button>
         </ScrollLink>
         <a href="https://drive.google.com/file/d/1LRlDHeWoV-ItQYDNUwyD4M0L-eoq5cqS/view" target='_blank' rel="noopener noreferrer">
        <motion.button
        className='border-2 rounded flex items-center px-4 py-3 pt-3 ml-3 my-2 hover:bg-black border-yellow-300'
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 1 }}
         >
        Resume
        </motion.button>
        </a>
        </div>
        </div>
        </div>
        </div>    
  );
};

export default Home;

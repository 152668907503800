import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
import hug2 from '../assets/carbon.png'
import hug from '../assets/hug.png'
  
const P4 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
const goBack = () => {
  const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

  const scrollToTargetSection = () => {
    const targetElement = document.getElementById(targetSectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
};

  return (
    <motion.div
    ref={ref}
    initial={{ opacity: 1, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0}}
    transition={{ duration: 0.8 }}
    id="p4" name="project4" className="max-w w-full bg-[#ffdfad] text-[#101436] md:pb-10 text-center"style={{ fontFamily: "futura" }}>
    <div className="flex flex-col justify-center items-center ">
    <p className="pt-5 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5">Sentiment analysis using BERT models </p>
      {/* Project*/}
      <div className="md:max-w-[1700px] lg:max-w-[1700px] lg:w-full grid sm:grid-cols-2 md:gap-20 lg:gap-20 bg-[#FFF2DB] rounded-lg p-4 shadow-lg  text-[#000000]" style={{ fontFamily: "futura" }}>
        <div>
          <h2 className="sm:text-1xl md:text-3xl font-bold mb-4 underline">Sentiment Analysis Prediction on Clothing Dataset</h2>
          <p className='sm:1xl md:text-2xl pl-4' style={{ textAlign: "left"}}>
          This project aims to fine-tune a <span className='font-bold text-orange-700'>BERT (Bidirectional Encoder Representations from Transformers)</span>  model for predicting sentiments from clothing reviews.</p>

        <p className='sm:text-xs lg:text-3xl font-bold underline pt-6 pl-4'>Common Types of BERT models used for NLP tasks </p>
        {/* Table*/}
        <table className='border border-black mt-4 ' border="1" cellspacing="0" cellpadding="6">
          <tr className='border border-black'>
            <th className='border border-black'>Feature</th>
            <th className='border border-black'>BERT</th>
            <th className='border border-black'>RoBERTa</th>
            <th className='border border-black'>DistilBERT</th>
          </tr>
          <tr className='border border-black' >
            <td className='border border-black'><strong>Training Objectives</strong></td>
            <td className='border border-black'>MLM (Masked Language Model), NSP</td>
            <td className='border border-black'>MLM (Masked Language Model)</td>
            <td className='border border-black'>MLM (Masked Language Model)</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Data Preprocessing</strong></td>
            <td className='border border-black'>Random Masking</td>
            <td className='border border-black'>Dynamic Masking, No NSP</td>
            <td className='border border-black'>Random Masking, Pruning Attention</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Next Sentence Prediction (NSP)</strong></td>
            <td className='border border-black'>Yes</td>
            <td className='border border-black'>No</td>
            <td className='border border-black'>No</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Training Duration</strong></td>
            <td className='border border-black'>Extended</td>
            <td className='border border-black'>Longer, Larger Dataset</td>
            <td className='border border-black'>Shorter, Pruned Layers</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Sentence Embeddings</strong></td>
            <td className='border border-black'>[CLS] Token</td>
            <td className='border border-black'>No [CLS] Token for Sentence Tasks</td>
            <td className='border border-black'>[CLS] Token</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Batch Training</strong></td>
            <td className='border border-black'>Fixed Batch Size</td>
            <td className='border border-black'>Dynamic Batch Size</td>
            <td className='border border-black'>Smaller Model Size</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Model Size</strong></td>
            <td className='border border-black'>Large</td>
            <td className='border border-black'>Larger</td>
            <td className='border border-black'>Smaller</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Number of Layers</strong></td>
            <td className='border border-black'>Configurable, Typically 12 or 24</td>
            <td className='border border-black'>Configurable, Typically 12 or 24</td>
            <td className='border border-black'>Reduced (Distilled), Typically 6</td>
          </tr>
          <tr className='border border-black'>
            <td className='border border-black'><strong>Performance</strong></td>
            <td className='border border-black'>Benchmark Model</td>
            <td className='border border-black'>Improved Performance on Tasks</td>
            <td>Trade-Off between Size and Quality</td>
          </tr>
        </table>

          {/* Additional Information */}
          <div className="mt-4">
            <h2 className="underline sm:text-xs lg:text-3xl font-bold mb-2 pb-2 mt-6">Project Goals</h2>
            <ul className='sm:text-xs lg:text-2xl'>
              <div className='pl-3 ' style={{ textAlign: "left" }}> <p className='font-bold'>1. EDA (Exploratory Data Analysis):</p>&bull; Perform data cleaning and exploratory data analysis (EDA) on the dataset to uncover insights from product reviews.
              <p className='font-bold pt-4'>2. Test different types of pretrained BERT models on the dataset:</p>&bull; Test different types of BERT models from Hugging Face with varying output classes. This step involves experimenting with pretrained models to evaluate their performance on the dataset without fine-tuning.
              <p className='font-bold pt-4'>3. Decide on number of output classes and type of BERT model:</p>&bull; Make a decision on the number of output classes (2 classes, 3 classes or 5 classes) and the type of BERT model to use (BERT, roBERTa or distilBERT) for the final sentiment analysis model.
              <p className='font-bold pt-4'>4. Fine-tune BERT model:</p>&bull; Fine-tune the dataset after deciding on which type of BERT model to use and how many output classes for the final model. 
              </div>
            </ul>
            <div>
            <p className='mb-2 font-bold sm:text-xs lg:text-3xl underline mt-6'>About the Dataset</p>
            <p className="sm:text-xs lg:text-2xl" style={{ textAlign: "left"}}>
                The dataset used is about women's E-Commerce Clothing Reviews from Amazon. It is obtained from Kaggle. You can download the dataset from <a href="https://www.kaggle.com/datasets/nicapotato/womens-ecommerce-clothing-reviews" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">here</a>. 
              </p>
              <p className='mt-2 '>NOTE: If you would like to fine-tune a BERT model based on your own dataset, the fine-tuning steps outlined in this project are applicable.</p>
            </div>
            
          </div>
          <h2 className="underline sm:text-xs lg:text-3xl font-bold mb-1 pt-6">Pretrained BERT model's result:</h2>
      <table className="min-w-full border mt-5 border-black">
        <thead>
          <tr>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '400px' }}>Model</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Accuracy</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Precision</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Recall</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>F1 Score</th>
          </tr>		
        </thead>
        <tbody className="text-sm">
          {/* pretrained BERT model (5 output classes) */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">pretrained BERT model (5 output classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.566</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.653</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.566</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.592</td>
          </tr>

          {/* pretrained roBERTa model (3 output classes)	*/}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">pretrained roBERTa model (3 output classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.793</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.771</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.793</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.776</td>

          </tr>

          {/* pretrained distilBERT (2 output classes)	 */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">pretrained distilBERT (2 output classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.837</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.850</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.837</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.842</td>
          </tr>
        </tbody>
      </table>
      <ul className='list-disc pl-6 pt-5 sm:text-xs lg:text-2xl mb-4' style={{textAlign:'left'}}>
        <li>pretrained BERT with 5 output classes (1 star to 5 star)</li>
        - <a href="https://huggingface.co/nlptown/bert-base-multilingual-uncased-sentiment" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">Link to the model</a>
        <li>pretrained roBERTa with 3 output classes (0 : Negative, 1 : Neutral, 2 : Positive) </li>
        - <a href="https://huggingface.co/cardiffnlp/twitter-roberta-base-sentiment-latest" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">Link to the model</a>
        <li>pretrained distilBERT with 2 output classes (0 : Negative, 1 : Positive)</li>
        - <a href="https://huggingface.co/distilbert-base-uncased-finetuned-sst-2-english" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">Link to the model</a>
      </ul>
  </div>

  
  <div>
  <div>
  <p className='font-bold underline lg:text-3xl'>Decision on how many output classes to use for fine-tuning</p>  
      <p className='lg:text-2xl pt-4 pl-4' style={{textAlign:'left'}}>The chosen number of output classes for this project is 3 output classes: <br /><span className='font-bold'>(0: Negative, 1: Neutral, 2: Positive)</span> </p>
      <div className='pt-4' style={{textAlign:'left'}}>
      <li className='lg:text-2xl pb-2'> <span className='font-bold'>Multi-class Classification (5 classes)</span>: Avoided due to the dataset's narrow sentiment ranges, requiring a larger dataset for effective capture.</li>
      <li className='lg:text-2xl pb-2'> <span className='font-bold'>Binary Classification (2 classes):</span> Not chosen as the dataset's rating distribution is relatively balanced; binary classification risks oversimplifying and losing information.</li>
      <li className='lg:text-2xl'> Chose <span className='font-bold'>3 output classes</span> to distinguish between positive, negative, and neutral sentiments, providing richer insights.</li>
      </div>
    </div>
    <h1 className='font-bold underline lg:text-3xl mt-6'>Decision on which type of BERT model to use for fine-tuning</h1> 
      <h2 className='lg:text-2xl pt-4' style={{textAlign:'left'}}>
      <li>For this project, the choice will be <span className='font-bold text-orange-700'>distilBERT</span> over BERT and roBERTa because distilBERT has a <span className='font-bold text-orange-700'>faster performance</span> in both training and inference times.</li> 
      <li>DistilBERT's <span className='font-bold text-orange-700'>smaller size</span> and streamlined architecture contribute to quicker computations, ensuring computational efficiency throughout the model's lifecycle.</li>
      <li><a href="https://huggingface.co/distilbert-base-uncased" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">Link to the model</a></li>
      </h2>
     <h2 className="underline lg:text-3xl font-bold mb-1 pt-4"> Result comparison between fine-tuned distilBERT model and the pretrained models </h2>
        <table className="min-w-full border mt-5 border-black">
        <thead>
          <tr>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '360px' }}>Model</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Accuracy</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Precision</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>Recall</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '60px' }}>F1 Score</th>
          </tr>		
        </thead>
        <tbody className="text-sm">
          {/* pretrained BERT model (5 output classes) */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">pretrained roBERTa (3 classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.789	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.772	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.789</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.773</td>
          </tr>

          {/* pretrained roBERTa model (3 output classes)	*/}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">pretrained distilBERT (2 classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.837	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.850</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.837	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.842</td>
          </tr>

          {/* pretrained distilBERT (2 output classes)	 */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Fine-tuned distilBERT model (3 classes)</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.849	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.860	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.849	</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">0.853</td>
          </tr>
        </tbody>
      </table>

        <div className='' style={{ alignItems: 'center' }}>
        <p className='font-bold lg:text-3xl underline mb-2 mt-6'>Test out the fine-tuned model:</p>
        <h2 className="lg:text-3xl font-bold mb-2 underline">
          <a
            href="https://huggingface.co/ongaunjie/distilbert-cloths-sentiment"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-[#ffffff]"
          >
           Huggingface repository
          </a>
        </h2>
        <img className='sm:ml-0 ' src={hug} alt="icon" title='ReactJS' style={{ width: '750px', height: '350px' }} />
        <p className='font-bold lg:text-3xl underline mt-2 mb-3'>Run it on Python</p>
        <img className='sm:ml-0 ' src={hug2} alt="icon" title='ReactJS' style={{ width: '750px', height: '400px' }}/>

        </div>
           {/* GitHub Link for Project 1 */}
        <div className="max-w-[1000px] w-full lg:pt-5 mb-2">
          <h2 className="md:mb-0 lg:text-3xl font-bold lg:mb-2 underline">Check out my Github Repository for more info: </h2>
          <a 
            href="https://github.com/ongaunjie1/sentiment-analysis-BERT-tuning"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
            View on Github
          </a>
        </div>
        <div className="max-w-[1000px] w-full md:mb-0 lg:mb-5 ">
        </div>
         {/* Back to Projects Link */}
        <div className=' bg-[#42e480] rounded-lg p-1'>
              <Link to="/" onClick={goBack}>
                <p className="back-arrow text-[#000000] font-bold lg:text-4xl hover:text-[#ffffff]">&larr; Back to Projects</p>
              </Link>
            </div>
        </div>
      </div>
    </div>
</motion.div>
  );
};

export default P4;


import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ml from '../assets/ml2.jpg'
import object from '../assets/Object-Detection-Computer-vision-AI-M.webp'
import classi from '../assets/class.jpg'
import power from '../assets/power.png'
import senti from '../assets/senti.png'
import chat from '../assets/chat.png'
import {Link} from 'react-router-dom'

const Project = () => {
    const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.01,
    });
  
const shouldDisableInView = isSmallScreen || !ref;

  return (
    <div name='project' id='pg4' className='w-full text-black font-bold bg-[#d3b860e3] xl:pt-60 xl:pb-60'  style={{fontFamily: "ivy" }}>
      <div className='max-w-[1300px] mx-auto p-6 flex flex-col justify-center w-full h-full xl:h-screen pt-20 pb-20  '>
        <div className='pb-6 pl-20 ml-10 '>
        <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ duration: 0.4 ,delay: 0.1 }}
           >
          <p className='text-5xl font-bold inline border-b-4 border-[#e48226]'>Projects</p>
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ duration: 0.4 ,delay: 0.2}}
           >
          <p className=' lg:text-xl pt-4'>Check out some of my recent projects</p>
          </motion.div>
        </div>

        {/* Container */}
        <motion.div className='grid sm:grid-cols-2 md:grid-cols-2 gap-10 '>
          {/* Grid item */}
          <motion.div 
            ref={ref}
            style={{ backgroundImage: `url(${ml})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div ' 
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.3 }}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100' >
              <span className=' sm:text-1xl md:text-2xl font-bold text-white tracking-wider'>
              Regression / Classification
              </span>
              <div className='pt-8 text-center'>
                <a href='/project1'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
                </a>
              </div>
            </div>
          </motion.div>

          {/* Grid item */}
          <motion.div
            ref={ref}
            style={{ backgroundImage: `url(${object})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div'
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.4 }}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className=' text-2xl font-bold text-white tracking-wider'>
              Object Detection
              </span>
              <div className='pt-8 text-center'>
                <a href='/project2'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
                </a>
              </div>
            </div>
          </motion.div>

          {/* Grid item */}
          <motion.div
            ref={ref}
            style={{ backgroundImage: `url(${classi})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div'
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.5 }}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
              Image Classifier
              </span>
              <div className='pt-8 text-center'>
              <Link to="/project3" smooth={true} duration={500}>
              <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
              </Link>
              </div>
            </div>
          </motion.div>

          {/* Grid item */}
          <motion.div
            ref={ref}
            style={{ backgroundImage: `url(${senti})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div'
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.6 }}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
              Sentiment Analysis
              </span>
              <div className='pt-8 text-center'>
                <a href='/project4'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
                </a>
              </div>
            </div>
          </motion.div>

            {/* Grid item */}
          <motion.div
            ref={ref}
            style={{ backgroundImage: `url(${power})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div'
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.7}}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className=' text-2xl font-bold text-white tracking-wider'>
              Data Visualization
              </span>
              <div className='pt-8 text-center'>
                <a href='/project5'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
                </a>
              </div>
            </div>
          </motion.div>

          {/* Grid item */}
          <motion.div
            ref={ref}
            style={{ backgroundImage: `url(${chat})` }}
            className='shadow-lg shadow-[#040c16] group container flex justify-center rounded-md items-center mx-auto content-div'
            initial={{ opacity: 0 }}
            animate={{ opacity: shouldDisableInView ? 1 : (inView ? 1 : 1) }}
            transition={{ delay: 0.8 }}
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
              Chatbot Development
              </span>
              <div className='pt-8 text-center'>
                <a href='/project6'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-grey-700 font-bold text-lg'>SUMMARY</button>
                </a>
              </div>
            </div>
          </motion.div>
         
        </motion.div>
      </div>
    </div>
  );
};

export default Project;

import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
import p1 from '../assets/p1.png'
import hug from '../assets/hug.png'
  
const P5 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
const goBack = () => {
  const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

  const scrollToTargetSection = () => {
    const targetElement = document.getElementById(targetSectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
};

  return (
    <motion.div
    ref={ref}
    initial={{ opacity: 1, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0}}
    transition={{ duration: 0.8 }}
    id="p5" name="project5" className="w-full bg-[#ffdfad] text-[#101436] md:pb-10 mx-auto text-center"style={{ fontFamily: "futura" }}>
    <div className="flex flex-col justify-center items-center w-full">
    <p className="pt-5 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5">PowerBI Dashboard: Movie Dataset</p>
      {/* Project*/}
      <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-20 bg-[#FFF2DB] rounded-lg p-4 shadow-lg  text-[#000000]" style={{ fontFamily: "futura" }}>
        <div>
          <h2 className="lg:text-3xl font-bold mb-2 underline">About the project</h2>
          <p className='lg:text-2xl pl-4' style={{ textAlign: "left"}}>
          This project aims to create an interactive multi-page PowerBI dashboard using the movie data extracted from themoviedb's API endpoint.</p>
        
          {/* Additional Information */}
          <div className="mt-2">
            <h2 className="underline lg:text-3xl font-bold pb-2 mt-2">Project Goals</h2>
            <ul className='lg:text-2xl'>
              <div className='pl-3 ' style={{ textAlign: "left" }}> <p className='font-bold'>1. Data Collection:</p>- The movie data was collected from themoviedb's API endpoint. You can find the documentation for the API <a href="https://developer.themoviedb.org/reference/intro/getting-started" className="text-blue-600 underline hover:text-[#ffffff]" target="_blank" rel="noopener noreferrer">here</a>.
              <p className='font-bold pt-2'>2. Perform feature engineering</p>- This step involves creating new columns such as profits, ROI, year and day columns to enrich the dataset.
              <p className='font-bold pt-2'>3. Create the PowerBI dashboard:</p>- Load the dataset into PowerBI and create an interactive multi-page PowerBI dashboard.
              </div>
            </ul>
            <div>
            <p className='mb-2 font-bold lg:text-3xl underline mt-2'>About the Dataset</p>
            <p className="lg:text-2xl" style={{ textAlign: "left"}}>
             The data extracted from themoviedb consists of the top 500 US-affiliated movies of each year, ranging from 2010 to 2022, and it is collected based on the vote counts from themoviedb.
              </p>
            <p className='lg:text-3xl mt-2 font-bold underline'>PowerBI Dashboard Overview:</p>
            <p className='lg:text-2xl pl-4 mt-2 mb-1' style={{textAlign:'left'}}>The dashboard contains 4 pages: </p>
            <div className='lg:text-2xl'  style={{textAlign:'left'}}>
            <li><span className='font-bold'>Main Page:</span> A comprehensive overview of key metrics and trends.</li>
            <li><span className='font-bold'>Graphs:</span> Visual representations of data trends, enabling in-depth analysis.</li>
            <li><span className='font-bold'>Movie Details</span>: Dive into specifics with detailed information about each movie.</li>
            <li><span className='font-bold'>Key Influencers: </span>Discover the factors influencing key aspects.</li>
            </div>
          <p className='font-bold underline lg:text-3xl pt-3'>PowerBI Dashboard Features:</p>  
              <p className='lg:text-2xl pt-2 pl-4' style={{textAlign:'left'}}>Features added to make the Dashboard more interactive and user-friendly:</p>
              <div className='pt-2' style={{textAlign:'left'}}>
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Sidebar Navigation</span>: A user-friendly sidebar with buttons for easy page navigation.</li>
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Bookmarks</span>:  Seamlessly toggle between different states, such as showing or hiding the sidebar.</li>    
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Field Parameters</span>: Effortlessly filter visuals based on different fields, such as top movies by budget, popularity, and more.</li> 
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Simple Slicers</span>: Easily filter movies by release date and genres.</li>    
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Drillthrough feature</span>: Added Drillthrough capability for the movie details.</li>  
              <li className='lg:text-2xl pb-2'> <span className='font-bold'>Key influencers analysis</span>: Added key influencers tools to analyze factors that have the most impact on a particular outcome.</li>  
            </div>
            </div>
          </div>
  </div>

  
  <div>
  <div>
    <p className='lg:text-3xl font-bold underline'>Dashboard showcase:</p>
    <ul className='lg:text-2xl font-bold' style={{textAlign:'left'}}>
      <li className='mt-3 underline mb-2'># Main Page:</li>
      <img src={p1} alt="main page" />
    </ul>
    <h2 className="underline lg:text-3xl font-bold mb-1 pt-6">Dashboard description:</h2>
      <table className="min-w-full border mt-5 border-black">
    <thead>
      <tr>
        <th className="border p-1 lg:text-lg border-black" style={{ width: '10px' }}>Number</th>
        <th className="border p-1 lg:text-lg border-black" style={{ width: '60px' }}>Description</th>
      </tr>		
    </thead>
    <tbody className="lg:text-sm">
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">1</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Genre Filter button</td>         
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">2</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Main Page navigation button</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">3</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Graph Page Navigation</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">4</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Movie Details Page Navigation</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">5</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Key Influencers Page Navigation</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">6</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Clear Slicer Settings</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">7</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Year Slicer</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">8</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Cards showing titles with Longest run-time, Highest ROI and Highest Profit</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">9</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Bar Plot (based on selected metric)</td>
      </tr>
      <tr>
        <td className="border p-1 lg:text-lg font-bold border-black">10</td>
        <td className="border p-1 lg:text-lg font-bold border-black">Movie Information Table</td>
      </tr>
    </tbody>
  </table>

    </div>
          <p className='pt-4'><a 
            href="https://drive.google.com/file/d/1YU_ShoHVtJM5VxyiRF93MqkaV_Es9mtT/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
           Download the Dashboard (pbix format)
          </a>
          </p>
        <div className='' style={{ alignItems: 'center' }}>
        </div>
           {/* GitHub Link for Project 1 */}
        <div className="max-w-[1000px] w-full  pt-3 mb-2">
          <h2 className="lg:text-3xl font-bold mb-2 underline">Check out my Github Repository for more info: </h2>
          <a 
            href="https://github.com/ongaunjie1/API_DataCollection_and_PowerBI_Visuals"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
           View on Github
          </a>
        </div>
        <div className="max-w-[1000px] w-full mb-5 ">
        </div>
         {/* Back to Projects Link */}
        <div className=' bg-[#42e480] rounded-lg p-4'>
              <Link to="/" onClick={goBack}>
                <p className="back-arrow text-[#000000] font-bold lg:text-4xl hover:text-[#ffffff]">&larr; Back to Projects</p>
              </Link>
            </div>
        </div>
      </div>
    </div>
</motion.div>
  );
};

export default P5;


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
import ball from '../assets/bball.png'
import dog from '../assets/dog.png'
import card from '../assets/card.png'
  
const P3 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
const goBack = () => {
  const targetSectionId = 'pg4'; // Replace with the id of the target section on the home page

  const scrollToTargetSection = () => {
    const targetElement = document.getElementById(targetSectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setTimeout(scrollToTargetSection, 50); // Adjust the delay as needed
};

  return (
    <motion.div 
    style={{ fontFamily: 'futura' }}
    ref={ref}
    initial={{ opacity: 1, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0}}
    transition={{ duration: 0.8 }}
    id="p3" name="project3" className="w-full bg-[#ffdfad] text-[#101436] md:pb-10 mx-auto text-center">
    <div className="flex flex-col justify-center items-center w-full">
    <p className="pt-5 lg:text-5xl font-bold text-black inline border-b-4 border-[#e48226] mb-5">Image classification projects</p>
      {/* Project*/}
      <div className="max-w-[1700px] w-full grid sm:grid-cols-2 gap-5 bg-[#FFF2DB] rounded-lg p-4 shadow-lg mb-20 text-[#000000]" style={{ fontFamily: "futura" }}>
        <div>
          <h2 className="lg:text-3xl font-bold mb-4 underline">Project Goal</h2>
          <p className='lg:text-2xl pl-4' style={{ textAlign: "left"}}>
          This project aims to utilize <span className='text-orange-700 font-bold'>EfficientNet</span> (CNN) for creating custom-trained image classification models for 3 different applications.</p>
          <ul>
            <li className='lg:text-2xl pt-5 font-bold' style={{ textAlign: "left"}}>1. Sports Classifier (Multi-class) </li>
            <p className='lg:text-2xl pl-6' style={{ textAlign: "left"}}>&bull; Able to predict up to 100 different classes of sports.</p>
            <li className='lg:text-2xl pt-5 font-bold' style={{ textAlign: "left"}}>2. Playing Cards Classifier (Multi-class)  </li>
            <p className='lg:text-2xl pl-6' style={{ textAlign: "left"}}>&bull; Able to predict up to 53 different classes of playing cards including the joker.</p>
            <li className='lg:text-2xl pt-5 font-bold' style={{ textAlign: "left"}}>3. Dog Classifier (Multi-class)  </li>
            <p className='lg:text-2xl pl-6' style={{ textAlign: "left"}}>&bull; Able to predict up to 120 different dog breeds.</p>
          </ul>

          {/* Additional Information */}
          <div className="mt-6">
            <h2 className="underline lg:text-3xl font-bold mb-2 pb-2">General steps for fine-tuning a pre-trained model </h2>
            <p className='lg:text-2xl pl-4 pb-4'style={{ textAlign: "left"}} >NOTE: Other CNNs models such as ResNet or mobilenet are applicable as well. You can find these models
            <a 
            href="https://huggingface.co/timm"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-2xl font-bold hover:text-[#ffffff]"
            style={{ marginLeft: "8px" }}
          >
          here
          </a>.</p>
            <ul className='lg:text-2xl'>
              <div className='pl-3 pt-2' style={{ textAlign: "left" }}> <p className='font-bold'>1. Label images:</p>&bull; For image classification tasks, the labeling process involves assigning a class label to each image and organizing them into folders based on their classes. Tools like RoboFlow or CVAT simplify this process.
              <p className='font-bold pt-4'>2. Split Data:</p>&bull; Divide dataset into training and validation sets. The training set is used to train the model, while the validation set is used to evaluate its performance during training.<br /> 
              <p className='font-bold pt-4'>3. Pre-process the images: (Resize images and batching dataset)</p>&bull; Resize images into appropriate sizes, each pre-trained model has a different image size. For EfficientNet, the image size used was 128x128 for the smallest model.<br />
              &bull; Batch size for image classification can vary, a good starting point is 32 for batch size.
              <p className='font-bold pt-4'>4. Augment Images (if needed):</p>&bull; Data augmentation techniques can be applied to artificially increase the diversity <br /> of your dataset. This helps the model generalize better.<br /> 
              <p className='font-bold pt-4'>5. Train the model:</p>&bull; The models were trained using PyTorch, feel free to use tensorflow if you want. Monitor metrics such as train loss and val loss during training.<br /> 
              <p className='font-bold pt-4'>6. Validate the Model:</p>&bull; Evaluate the trained model on the validation set to ensure it generalizes well <br />to new, unseen data.<br /> 
              <p className='font-bold pt-4'>7. Inference on Test Set:</p>&bull; After training, perform inference on a separate test set to assess the model's performance on completely unseen data.<br /> 
              </div>
            </ul>
          </div>
          <h2 className="underline lg:text-3xl font-bold mb-1 pt-8">Custom-trained model's result:</h2>
      <table className="min-w-full border mt-5 border-black">
        <thead>
          <tr>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '300px' }}>Model</th>
            <th className="border p-2 lg:text-2xl border-black" style={{ width: '150px' }}>Accuracy</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {/* Playing card classifier */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Playing cards classifier</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">97%</td>
          </tr>

          {/* Sports classifier */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Sports Classifier</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">93%</td>

          </tr>

          {/* Dog classifier */}
          <tr>
            <td className="border p-2 lg:text-2xl font-bold border-black">Dog Classifier</td>
            <td className="border p-2 lg:text-2xl font-bold border-black">75%</td>
          </tr>
        </tbody>
      </table>
        </div>
        <div>
        <div>
    </div>
        <div className='' style={{ alignItems: 'center' }}>
        <p className='font-bold lg:text-3xl underline pb-3'>Check out my image classifier apps below:</p>
        <h2 className="lg:text-3xl font-bold mb-2 underline">
          <a
            href="https://sports-classifier.streamlit.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-[#ffffff]"
          >
           Sports classifier app:
          </a>
        </h2>
        <img className='pl-10 sm:ml-0 md:ml-20' src={ball} alt="icon" title='ReactJS' style={{ width: '600px', height: '400px' }} />
        <h2 className="lg:text-3xl font-bold pt-2 pb-2 underline">
          <a
            href="https://playingcardclassifier-efficientnet.streamlit.app//"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-[#ffffff]"
          >
          Playing cards classifier app:
          </a>
        </h2>
        <img className='pl-10 sm:ml-0 md:ml-20' src={card} alt="icon" title='ReactJS' style={{ width: '600px', height: '400px' }} />
        <h2 className="lg:text-3xl font-bold pt-2 pb-2 underline">
          <a
            href="https://dogclassifier-efficientnet.streamlit.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-[#ffffff]"
          >
          Dog classifier app:
          </a>
        </h2>
        <img className='pl-10 sm:ml-0 md:ml-20' src={dog} alt="icon" title='ReactJS' style={{ width: '600px', height: '400px' }} />
          </div>
          <ul className='lg:text-1xl'>
          </ul>
           {/* GitHub Link for Project 1 */}
        <div className="max-w-[1000px] w-full  pt-5 mb-2 sm:ml-0 md:ml-20 pl-5" style={{textAlign:'left'}}>
          <h2 className="lg:text-3xl font-bold mb-2 underline">Check out my Github Repository for more info: </h2>
          <a 
            href="https://github.com/ongaunjie1/sports-classifier"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
            Link for Sports Classifier
          </a>
        </div>
        <div className="max-w-[1000px] w-full mb-2 sm:ml-0 md:ml-20 pl-5 " style={{textAlign:'left'}}>
          <a 
            href="https://github.com/ongaunjie1/playing_card_classifier"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
           Link for Playing Cards Classifier
          </a>
        </div>
        <div className="max-w-[1000px] w-full mb-5 sm:ml-0 md:ml-20 pl-5 " style={{textAlign:'left'}}>
          <a 
            href="https://github.com/ongaunjie1/dog_classifier"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline lg:text-3xl hover:text-[#ffffff]"
          >
           Link for Dog Classifier
          </a>
        </div>
         {/* Back to Projects Link */}
        <div className=' bg-[#42e480] rounded-lg p-4'>
              <Link to="/" onClick={goBack}>
                <p className="back-arrow text-[#000000] font-bold lg:text-4xl">&larr; Back to Projects</p>
              </Link>
            </div>
        </div>
      </div>
    </div>
</motion.div>
  );
};

export default P3;


import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


  const About = () => {
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger the animation once
      threshold: 0.10, // Trigger animation when the section is 10% visible
    });

  return (
    <div id="pg2" name="about" className=" bg-[#dac190] text-black pt-5"> 
      <div className="flex flex-col justify-center h-screen 2xl:mt-20 2xl:pb-20"> 
      <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
            transition={{ duration: 0.8 }}
            className=" 2xl:text-center pb-4 mt-0 2xl:mr-6 text-center text-3xl" 
          >
            <p className="sm:text-xl md:text-4xl 2xl:text-5xl text-black font-bold inline border-b-4 border-[#e48226]" style={{fontFamily: "ivy" }}
            >My background</p>
          </motion.div>
        <div className="max-w-[1345px] w-full gap-0 pr-3 md:py-1 2xl:py-4"> 
        </div>
        <div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="md:mt-6 md:mb-6 md:text-[1.35rem] 2xl:text-[1.9rem] font-bold text-black xl:pl-80 xl:pr-80 pl-3 pr-3"
            style={{ fontFamily: "futura"}}
          >
            <p className='' >
              A <span className="text-orange-700">former manufacturing engineer </span>with 2 years of experience working in esteemed MNC companies like Daikin Industries and NXP Semiconductors.   
              I am currently <span className="text-orange-700">transitioning into the field of Data Science</span>. Combining my engineering background with a passion for uncovering insights from data,
              I made the decision to pursue a career focused on making data-driven decisions.
            </p>
            <div className='mt-5'>
              <p>
                Within this portfolio website, you will find a collection of my <span className="text-orange-700">data science projects. </span>
                These projects exemplify the skills and knowledge I have acquired through <span className="text-orange-700">self-learning</span>. They serve as concrete examples of my abilities and expertise in the field.
              </p>
            </div>
          </motion.div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className=" md:py-8 sm:text-xs md:text-[1.2rem] 2xl:text-[1.3rem] font-bold text-black  md:pl-20 2xl:pt-10 3xl:mr-20 overflow-x-scroll lg:overflow-x-hidden" style={{ fontFamily: "futura", fontSize: "" }}>
            <svg width="1000" height="100" className="">
              <line x1="20" y1="50" x2="840" y2="50" style={{ stroke: 'black', strokeWidth: 1.5 }}></line>
              <circle cx="30" cy="50" r="10" stroke="#f8f9fa" strokeWidth="6" fill="#dd6b20"></circle>
              <text textAnchor="middle" x="30" y="88" fill="black" fontSize="">2020</text>
              <circle cx="300" cy="50" r="10" stroke="#f8f9fa" strokeWidth="6" fill="#dd6b20"></circle>
              <text textAnchor="middle" x="297" y="90" fill="black" font-size="">2022</text>
              <text fontSize="13" textAnchor="middle" x="170" y="30" fill="black" font-size="">Production Engineer</text>
              <text style={{ fontStyle: 'italic' }} fontSize="" textAnchor="middle" x="166" y="80" fill="#C95103" font-size="">Daikin Malaysia Sdn Bhd</text>

              <circle cx="564" cy="50" r="10" stroke="#f8f9fa" strokeWidth="6" fill="#dd6b20"></circle>
              <text textAnchor="middle" x="564" y="88" fill="black" fontSize="">2023</text>
              <text fontSize="13" textAnchor="middle" x="435" y="30" fill="black" font-size="">Equipment Engineer</text>
              <text style={{ fontStyle: 'italic' }} fontSize="" textAnchor="middle" x="432" y="80" fill="#C95103" font-size="">NXP Malaysia Sdn Bhd</text>

              <circle cx="831" cy="50" r="10" stroke="#f8f9fa" strokeWidth="6" fill="#dd6b20"></circle>
              <text textAnchor="middle" x="831" y="88" fill="black" fontSize="">PRESENT</text>
              <text fontSize="13" textAnchor="middle" x="702" y="30" fill="black" font-size="">Career Transition</text>
              <text style={{ fontStyle: 'italic' }} fontSize="" textAnchor="middle" x="702" y="80" fill="#C95103" font-size="">Self-learning</text>
            </svg>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;

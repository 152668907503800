import React from 'react'

const Contact = () => {
  return (
    <div name='contact' id='pg5' className='w-full md:h-screen bg-[#0a192f] flex justify-center items-center p-4 sm:pt-0 xl:pt-10'  style={{fontFamily: "ivy" }}>
        <form method='POST' action="https://getform.io/f/b84509cf-d945-4a68-8e40-5ba85f5ee164" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-4 pt-10 xl:pt-20'>
                <p className='xl:text-5xl text-4xl text-white font-bold inline border-b-4 border-[#e48226] '>Contact</p>
                <p className='text-white py-4 text-xl font-sans'> // Submit the form below or contact me via the social icons</p>
            </div>
            <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='Email' />
            <textarea className='bg-[#ccd6f6] ' name="message" rows="10" placeholder=' Message'></textarea>
            <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 my-8 mx-auto flex items-center'> Let's collaborate</button>
            <p className='pt-0 xl:pt-12 justify-center items-center flex text-bold text-white text-1xl'>© 2023 Designed and Built by Ong Aun Jie</p>
        </form>
    </div>
    
  )
}

export default Contact  
import { useState } from 'react';
import dashboard from '../assets/a1.png';
import ml from '../assets/coding.png';
import c1 from '../assets/c1.png';
import datacollection from '../assets/data-collection.png';
import data from '../assets/data.png';
import python from '../assets/python.png';
import tailwind from '../assets/react.png';
import react from '../assets/tailwind.png';
import postgres from '../assets/postgresql.png';
import html from '../assets/html.png';
import panda from '../assets/a.png'
import numpy from '../assets/nump.png'
import Excel from '../assets/excel.png'
import RegEX from '../assets/regex_icon_132036.png'
import matplotlib from '../assets/pngaaa.com-4152242.png'
import others from '../assets/tools.png'
import plotly from '../assets/plotly_logo_icon_248823.png'
import powerbi from '../assets/Power-BI.png'
import seaborn from '../assets/logo-tall-lightbg.png'
import api from '../assets/api_icon_129131.png'
import scrap from '../assets/scraping.png'
import scikit from '../assets/640px-Scikit_learn_logo_small.svg.png'
import hugging from '../assets/10022huggingface_110042.png'
import torch from '../assets/pytorch_logo_icon_169823.png'
import opencv from '../assets/opencv_logo_icon_170888.png'
import yolo from '../assets/Ultralytics_YOLO_full_blue.png'
import streamlit from '../assets/streamlit-logo-primary-colormark-darktext.png'
import docker from '../assets/docker_original_wordmark_logo_icon_146557.png'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Skills = () => {
  const [selectedSkill, setSelectedSkill] = useState(null);

  const handleSkillClick = (skill) => {
    setSelectedSkill(skill);
  };

  const handleReset = () => {
    setSelectedSkill(null);
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;

  return (
    <div id='pg3' name="skills" className="text-center w-full xl:pt-20 xl:pb-20 lg:pr-11  md:pb-5 bg-[#ddb77e] text-black font-bold" >
      {/* Container */}
      <div className="max-w-[1250px] mx-auto p-4 flex flex-col justify-center w-full h-screen">
        {!selectedSkill && (
          <div className='pb-4 lg:pl-10'>
            <motion.p
              className="md:text-5xl sm:text-3xl font-bold inline border-b-4 xl:mr-20 text-4xl border-[#e48226]" style={{ fontFamily: "ivy" }}
              ref={ref}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }}
              transition={{ duration: 0.2, delay: 0.1 }}
            >
              Skills
            </motion.p>
          </div>
        )}
        {!selectedSkill && (
          <div className="flex flex-wrap justify-center sm:pt-0 lg:pt-10 cursor-pointer" style={{ fontFamily: "ivy" }}>
            <motion.div
              className={`flex flex-col items-center mx-6 my-3 ${isSmallScreen ? '' : ''}`}
              onClick={() => handleSkillClick('programming')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
            >
              <motion.img
                className={`sm:w-10 md:w-1/6 lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/5 sm:w-32' : ''}`}
                src={c1}
                alt="language"
              />
              <motion.p className={`pt-3 text-xs sm:text-2xl lg:block md:hidden ${isSmallScreen ? 'text-base hidden' : ''}`}>Programming languages</motion.p>
            </motion.div>

            <motion.div
              className={`flex flex-col items-center mx-6 my-3 ${isSmallScreen ? 'w-1/2' : ''}`}
              onClick={() => handleSkillClick('data-analysis')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 0.4 }}
            >
              <motion.img
                className={`sm:w-10 md:w-1/6  lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/3 sm:w-32' : ''}`}
                src={data}
                alt="data"
              />
              <motion.p className={`pt-3 pb-5 text-xs sm:text-2xl lg:block md:hidden ${isSmallScreen ? 'text-base hidden' : ''}`}>Data Analysis and Manipulation</motion.p>
            </motion.div>

            <motion.div
              className={`flex flex-col items-center mx-6 my-3 pr-  ${isSmallScreen ? 'w-1/2 ' : ''}`}
              onClick={() => handleSkillClick('data-visualization')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 0.6 }}
            >
              <motion.img
                className={`sm:w-10 lg:mr-20 md:w-1/6 lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/3 sm:w-32' : ''}`}
                src={dashboard}
                alt="visualization"
              />
              <motion.p className={`pt-3 lg:mr-20 text-xs sm:text-2xl lg:block md:hidden   ${isSmallScreen ? 'text-base ml-10 hidden' : ''}`}>Data Visualization</motion.p>
            </motion.div>

            <motion.div
              className={`flex flex-col items-center mx-6 my-3 ml-0 lg:pr-5  ${isSmallScreen ? 'pl-5 w-1/2' : ''}`}
              onClick={() => handleSkillClick('data-collection')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 0.8 }}
            >
              <motion.img
                className={`sm:w-10 md:w-1/6 md:ml-3 2xl:ml-10 lg:mr-3 lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/3 sm:w-32' : ''}`}
                src={datacollection}
                alt="tool"
              />
              <motion.p className={`pt-3 lg:mr-3 text-xs sm:text-2xl lg:block md:hidden ${isSmallScreen ? 'text-base ml-9 hidden' : ''}`}>Data Collection</motion.p>
            </motion.div>

            <motion.div
              className={`flex flex-col items-center mx-6 my-3 ${isSmallScreen ? 'w-1/2' : ''}`}
              onClick={() => handleSkillClick('machine-learning')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 1 }}
            >
              <motion.img
                className={`sm:w-10 md:w-1/6 lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/3 sm:w-32' : ''}`}
                src={ml}
                alt="ml"
              />
              <motion.p className={`pt-3 text-xs sm:text-2xl lg:block md:hidden ${isSmallScreen ? 'text-base hidden' : ''}`}>Machine Learning and Deep Learning</motion.p>
            </motion.div>

            <motion.div
              className={`flex flex-col items-center mx-6 my-3 xl:pb-10 ${isSmallScreen ? 'w-1/2' : ''}`}
              onClick={() => handleSkillClick('others')}
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.2, delay: 1.2 }}
            >
              <motion.img
                className={`sm:w-10 md:w-1/6 xl:mr-14 lg:w-40 hover:scale-110 duration-500 ${isSmallScreen ? 'w-1/3 sm:w-32 mr-2' : ''}`}
                src={others}
                alt="ml"
              />
              <motion.p className={`pt-3 text-xs sm:text-2xl lg:block xl:mr-10 md:hidden ${isSmallScreen ? 'text-base hidden' : ''}`}>Others</motion.p>
            </motion.div>
          </div>
        )}
        {selectedSkill && (
          <div className="selected-skill-content" style={{ fontFamily: "futura" }} >
            <div className="selected-skill-content">
            {selectedSkill === 'programming' && (
              <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{duration: 0.5, ease: "easeInOut", delay: 0.1 }}
            >
                <div className="bg-[#FFF2DB] p-4 rounded-lg inline-block max-w-[80%]">
                  <p className={` md:text-6xl font-bold ${isSmallScreen ? 'text-2xl' : ''}`}>
                    Programming Languages
                  </p>
                  <p className={`sm:text-xl md:text-3xl font-bold mb-5 pt-7`}>Languages I Utilize:</p>
                  <ul className={`text-base sm:text-2xl list-disc pl-8`} style={{ textAlign: "left"}}>
                    <li className='pb-2'><span className="text-orange-700"> Python</span> for data science, machine learning, and automation</li>
                    <li className='pb-2'><span className="text-orange-700"> SQL</span> for querying and managing relational databases</li>
                    <li><span className="text-orange-700"> HTML</span> ,<span className="text-orange-700"> Tailwind CSS</span> ,
                    <span className="text-orange-700"> ReactJS</span>  for creating engaging and responsive front-end interfaces
                    </li>
                  </ul>
                  <div className='pt-10 md:flex sm:flex hidden ' style={{ display: '', alignItems: 'center' }}>
                    <motion.img className='hover:scale-110 transition-none' src={python} alt="icon" title="Python" style={{ width: '100px', height: '100px', marginLeft: '106px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={postgres} alt="icon" title='PostgreSQL' style={{ width: '100px', height: '100px', marginLeft: '50px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={html} alt="icon" title='HTML' style={{ width: '100px', height: '100px', marginLeft: '50px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={react} alt="icon" title='Tailwind CSS' style={{ width: '100px', height: '100px', marginLeft: '50px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={tailwind} alt="icon" title='ReactJS' style={{ width: '100px', height: '100px', marginLeft: '50px' }} />
                  </div>
                </div>
                </motion.div>
              )}

            {selectedSkill === 'data-analysis' && (
              <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{duration: 0.5, ease: "easeInOut", delay: 0.1 }}
            >
                <div className="bg-[#FFF2DB] p-4 rounded-lg inline-block max-w-[80%]">
                  <p className={`md:text-5xl font-bold ${isSmallScreen ? 'text-1xl' : ''}`}>
                    Data analysis and manipulation
                  </p>
                  <p className={`sm:text-xs md:text-3xl font-bold mb-2 pt-7`}>Tools I Utilize:</p>
                  <ul className={`list-disc pl-8 text-xs `} style={{ textAlign: "left"}}>
                    <li className='pb-3 pr-0 md:text-2xl'><span className="text-orange-700">Pandas library</span> for data cleaning, transforming, and also performs Exploratory Data Analysis (EDA) on tabular datasets in Python</li>
                    <li className='pb-3 pr-0 md:text-2xl'><span className="text-orange-700">NumPy library</span> for analyzing and manipulating numerical data in Python</li>
                    <li className='pb-3 md:text-2xl'><span className="text-orange-700">Excel's</span> powerful features such as XLOOKUP, Pivot Tables, and Power Query for simple data analysis and manipulation</li>
                    <li className='pr-1 md:text-2xl'><span className="text-orange-700">Regular expression (RegEX)</span> to perform complex searches, extractions, and transformations on textual data</li>
                  </ul>

                  <div className='pt-5 md:flex sm:flex hidden' style={{ display: '', alignItems: 'center' }}>
                    <motion.img className='hover:scale-110 transition-none' src={panda} alt="icon" title='Pandas' style={{ width: '130px', height: '100px', marginLeft: '120px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={numpy} alt="icon" title='NumPy' style={{ width: '170px', height: '100px', marginLeft: '45px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={Excel} alt="icon" title='Excel' style={{ width: '100px', height: '100px', marginLeft: '60px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={RegEX} alt="icon" title='RegEX' style={{ width: '100px', height: '100px', marginLeft: '75px' }} />
                  </div>
                </div>
                </motion.div>
              )}

              {selectedSkill === 'data-visualization' && (
              <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{duration: 0.5, ease: "easeInOut", delay: 0.1 }}
            >
                <div className="bg-[#FFF2DB] p-4 rounded-lg inline-block max-w-[80%]">
                  <p className={` md:text-5xl font-bold ${isSmallScreen ? 'text-xl' : ''}`}>
                    Data Visualization
                  </p>
                  <p className={`text-xl sm:text-3xl font-bold mb-2 pt-7 pb-2`}>Tools I Utilize:</p>
                  <ul className={`text-xs list-disc pl-8`} style={{ textAlign: "left"}} >
                    <li className='pb-3 md:text-2xl'><span className="text-orange-700"> PowerBI</span> for creating user-friendly, multi-page dashboards for dynamic and intuitive data visualization,enhancing user engagement and informed decision-making</li>
                    <li className='pb-3 md:text-2xl'><span className="text-orange-700"> Excel</span> for creating simple and effective charts and graphs to convey key insights efficiently</li>
                    <li className='md:text-2xl'><span className="text-orange-700">Matplotlib</span>,<span className="text-orange-700">Seaborn</span> and <span className="text-orange-700">Plotly</span> for visualizing and exploring data within python, allowing for exploratory data analysis to gain insights into the patterns,trends
                    and distribution within datasets</li>
                  </ul>
                  <div className='pt-8 md:flex sm:flex hidden' style={{ display: '', alignItems: 'center' }} >
                    <motion.img className='hover:scale-110 transition-none' src={powerbi} alt="icon" title='PowerBI' style={{ width: '85px', height: '100px', marginLeft: '60px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={Excel} alt="icon" title='Excel' style={{ width: '100px', height: '100px', marginLeft: '90px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={matplotlib} alt="icon" title='Matplotlib' style={{ width: '100px', height: '95px', marginLeft: '90px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={seaborn} alt="icon" title='Seaborn' style={{ width: '100px', height: '110px', marginLeft: '90px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={plotly} alt="icon" title='Plotly' style={{ width: '90px', height: '85px', marginLeft: '75px' }} />
                  </div>
                </div>
                </motion.div>
              )}

              {selectedSkill === 'data-collection' && (
              <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{duration: 0.5, ease: "easeInOut", delay: 0.1 }}
            >
                <div className="bg-[#FFF2DB] p-4 rounded-lg inline-block max-w-[80%]">
                  <p className={` md:text-5xl font-bold ${isSmallScreen ? 'text-xl' : ''}`}>
                    Data Collection
                  </p>
                  <p className={`sm:text-xl md:text-3xl font-bold mb-2 pt-7`}>Tools I Utilize:</p>
                  <ul className={` text-xs list-disc pl-8`} style={{ textAlign: "left"}}>
                    <li className='pb-3 md:text-2xl'><span className="text-orange-700"> SQL queries</span> to seamlessly extract and manipulate data from SQL databases, ensuring efficient and 
                    accurate data collection for insightful analysis</li>
                    <li className='pb-3 md:text-2xl'><span className="text-orange-700"> API Data Retrieval</span> by executing API calls to fetch information from web services. </li>
                    <li className='pb-3 md:text-2xl'>Web scraping techniques leveraging python libraries like <span className="text-orange-700">beautifulsoup</span>  for pulling HTML data, 
                    <span className="text-orange-700"> selenium</span> for scraping dynamic websites and <span className="text-orange-700">scrapy</span> for structured and scalable web crawling</li>
                  </ul>
                  <div className='pt-8 md:flex sm:flex hidden' style={{ display: '', alignItems: 'center' }}>
                    <motion.img className='hover:scale-110 transition-none' src={postgres} alt="icon" title='Postgres' style={{ width: '100px', height: '100px', marginLeft: '210px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={api} alt="icon" title='API retrieval' style={{ width: '100px', height: '100px', marginLeft: '120px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={scrap} alt="icon" title='Web-scraping' style={{ width: '100px', height: '95px', marginLeft: '120px' }} />
                  </div>
                </div>
                </motion.div>
              )}

              {selectedSkill === 'machine-learning' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.1 }}
                >
                  <div className="bg-[#FFF2DB] p-4 rounded-lg max-w-[100%] mx-auto mt-8">
                    <p className={`lg:pb-8 lg:text-5xl font-bold ${isSmallScreen ? 'text-[1.1rem]' : ''}`}>
                      Machine Learning and Deep Learning
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 md:gap-10 sm:gap-0 pl-5">
                      {/* Tabular Data Section */}
                      <div>
                        <p className={` underline sm:text-xl md:text-3xl font-bold mb-2`}>Tabular datasets:</p>
                        <ul className={`text-xs list-disc`} style={{ textAlign: "left"}}>
                          <li className='pb-3 pr-0 pl-0 md:text-2xl'>
                            Uses <span className="text-orange-700">scikit-learn</span> for <span className="text-orange-700">regression</span> and <span className="text-orange-700">classification</span> tasks
                          </li>
                          <li className='pb-3 pr-0 pl-0 md:text-2xl'>
                            Applies algorithms such as Random Tree,<span className="text-orange-700"> Random Forest</span>,  Linear Regression, <span className="text-orange-700">XGBoost</span> and more
                          </li>
                          <li className='pb-3 pr-0 pl-0 md:text-2xl '>
                            Fine-tune models using techniques like <span className="text-orange-700">GridSearchCV</span> and <span className="text-orange-700">RandomizedSearchCV</span>
                          </li>
                        </ul>
                      </div>

                      {/* Image Classification and Object Detection Section */}
                      <div>
                        <p className={`underline sm:text-xl md:text-3xl font-bold sm:mb-0 md:mb-2`}>Image datasets:</p>
                        <ul className={`text-xs list-disc sm:pl-0 md:pl-4`} style={{ textAlign: "left"}}>
                          <li className='pb-3 pl-0 pr-0 md:text-2xl'>
                            Uses <span className="text-orange-700">PyTorch</span> for computer vision tasks such as <span className="text-orange-700">image-classification</span> and <span className="text-orange-700">object-detection</span>
                          </li>
                          <li className='pb-3 pl-0 pr-0 md:text-2xl'>Implements CNNs such as <span className="text-orange-700">EfficientNet</span> for image classification</li>
                          <li className='pb-3 pl-0 pr-0 md:text-2xl'>
                            Utilizes open-source libraries such as<span className="text-orange-700"> YOLOv8</span> and <span className="text-orange-700">OpenCV</span> for custom object detection tasks
                          </li>
                        </ul>
                      </div>

                      {/* Natural Language Processing Section */}
                      <div>
                        <p className={`underline sm:text-xl md:text-3xl font-bold mb-2`}>Text-based datasets:</p>
                        <ul className={`text-xs list-disc sm:pl-0 md:pl-8`} style={{ textAlign: "left"}}>
                          <li className='md:pb-3 pl-0 pr-0 md:text-2xl'>
                            Uses <span className="text-orange-700">NLP</span> python libraries, such as <span className="text-orange-700">NLTK</span> for preprocessing text-based datasets
                          </li>
                          <li className='sm:mb-0 md:mb-3 pl-0 pr-0 md:text-2xl'>
                          Leverage <span className="text-orange-700">transformer-models</span> from <span className="text-orange-700">Hugging-Face</span>, such as <span className="text-orange-700">BERT</span>, for sentiment analysis and text-classification
                          </li>
                        </ul>
                      </div>
                      <div className='mt-0 md:flex sm:flex hidden' style={{ display: '', alignItems: 'center' }}>
                    <motion.img className='hover:scale-110 transition-none' src={scikit} alt="icon" title='Scikit-learn' style={{ width: '160px', height: '70px', marginLeft: '25px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={torch} alt="icon" title='pytorch' style={{ width: '170px', height: '100px', marginLeft: '80px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={yolo} alt="icon" title='yolov8' style={{ width: '200px', height: '50px', marginLeft: '80px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={opencv} alt="icon" title='OpenCV' style={{ width: '300px', height: '95px', marginLeft: '80px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={hugging} alt="icon" title='Hugging-face' style={{ width: '100px', height: '95px', marginLeft: '80px' }} />
                  </div>
                    </div>
                  </div>
                </motion.div>
              )}

                {selectedSkill === 'others' && (
              <motion.div
              initial={{ opacity: 0}}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{duration: 0.5, ease: "easeInOut", delay: 0.1 }}
            >
                <div className="bg-[#FFF2DB] p-4 rounded-lg inline-block max-w-[70%]">
                  <p className={`md:text-5xl font-bold ${isSmallScreen ? 'text-xl' : ''}`}>
                    Other skills
                  </p>
                  <p className={`sm:text-xl lg:text-3xl font-bold mb-2 lg:pt-7`}>Tools I Utilize:</p>
                  <ul className={`text-xs lg:text-2xl list-disc pl-8`} style={{ textAlign: "left"}}>
                    <li className='pb-3 '><span className="text-orange-700">Docker</span> to  containerize data science environments, ensuring reproducibility and easy collaboration
                    by encapsulating dependencies for seamless sharing and deployment of workflows</li>
                    <li className='pb-3'>Creates <span className="text-orange-700"> RESTful api</span> using <span className="text-orange-700">Flask</span> to establish endpoints for backend python applications, enabling 
                    efficient communication and data exchange with a front-end apps </li>
                    <li className='pb-3'>Utilize <span className="text-orange-700">Streamlit</span> for creating interactive and user-friendly front-end applications in data-science, specifically for maching-learning and 
                    deep-learning models. </li>
                  </ul>
                  <div className='pt-5 md:flex sm:flex hidden' style={{ display: '', alignItems: 'center' }}>
                    <motion.img className='hover:scale-110 transition-none' src={docker} alt="icon" title='Postgres' style={{ width: '100px', height: '100px', marginLeft: '105px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={api} alt="icon" title='API retrieval' style={{ width: '100px', height: '100px', marginLeft: '150px' }} />
                    <motion.img className='hover:scale-110 transition-none' src={streamlit} alt="icon" title='Web-scraping' style={{ width: '200px', height: '100px', marginLeft: '100px' }} />
                  </div>
                </div>
                </motion.div>
              )}
              <div className="mt-5">
                <button className="px-4 py-2 bg-[#e68c39] rounded font-bold text-2xl" onClick={handleReset}>
                  Select another skill
                </button>
              </div>
            </div>
          </div>
        )}
      
      </div>
    </div>
  );
};

export default Skills;
